<template>
  <div>
    <!-- Title -->
    <div class="sela-form-title mb-5">
      {{ t('change_item', { name: t('password') }) }}
    </div>

    <!-- Email -->
    <AuthTextField
      v-model="form.email"
      :label="t('email')"
      placeholder="example@email.com"
      type="email"
      error="email_reset-email"
      readonly
    />

    <!-- Password -->
    <AuthTextField
      v-model="form.password"
      :label="t('password')"
      placeholder="********"
      type="password"
      error="email_reset-password"
    />

    <!-- Password Confirmation -->
    <AuthTextField
      v-model="form.passwordConfirmation"
      :label="t('password_confirmation')"
      placeholder="********"
      type="password"
      error="email_reset-password_confirmation"
    />

    <!-- Submit -->
    <SelaResponseError
      class="my-2"
      api-key="email_reset"
      :except="[
        'email_reset-email',
        'email_reset-password',
        'email_reset-password_confirmation'
      ]"
    />

    <SelaSubmitButton
      class="my-2"
      :loading="form.loading"
      @submit="resetHandler"
    />
  </div>
</template>

<script setup lang="ts">
definePageMeta({ auth: false })

const { form, resetHandler } = useResetByEmail()
const { url } = useDomainHost()
const { t } = useI18n()

useServerSeoMeta(
  useOgMeta(
    url + '/auth/email-reset-password',
    t('password_reset'),
    t('sila_description'),
    url + '/images/sela.webp'
  )
)

useHead({
  title: t('password_reset'),
  link: [useSeoCanonical(url + '/auth/email-reset-password')],
  script: [
    useSeoBreadcrumb([
      useSeoBreadcrumbItem(1, t('home'), url),
      useSeoBreadcrumbItem(
        2,
        t('password_reset'),
        url + '/auth/email-reset-password'
      )
    ])
  ]
})
</script>
